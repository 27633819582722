class RouteUtils{
    constructor () {}

    // 树结构转列表
    treeToList(tree, list){
        if (tree !== undefined && tree.length > 0){
            for (let i = 0; i < tree.length; i++) {
                let menu = tree[i];
                if (menu.type === 'menu'){
                    list.push(menu)
                }
                if (menu.children.length > 0){
                    this.treeToList(menu.children, list)
                }
            }
        }
        return list;
    }

    //组件转换
    componentConvert(menu){
        return {
            path: menu.path,
            name: menu.name,
            icon: menu.icon,
            component: () => import('@/pages/' + menu.component),
            children: [],
            meta: {
                title: menu.name,
                type: menu.type,
                buttonList: menu.buttonList
            }
        };
    }

    //根据path查找路由
    findMenuByPath(routes, path){
        let route = routes.find(route => route.path === path)
        return route === undefined ? undefined : this.convertMenu(route)
    }

    convertMenu(route){
        if (route === undefined){
            return undefined
        }
        return {
            name: route.name,
            path: route.path,
            // component: route.component || '',
            // children: route.children || []
        }
    }

    getPermissionList(route){
        if (route === undefined){
            return []
        }
        // console.log('www0', route.value.meta.buttonList)
        let btnList = route.value.meta.buttonList
        let permissionList = []
        if (btnList !== undefined) {
            btnList.forEach(e =>{
                let ps = e.perms.split(',')
                ps.forEach(p =>{permissionList.push(p)})
            })
        }
        return permissionList;
    }
}

export default new RouteUtils()