import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './router/globalGuard'
import * as ElIcons from '@element-plus/icons-vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

//markdown
import VueMarkdownEditorComponent from './vue-markdown'

const app = createApp(App);

app.use(ElementPlus, {
    locale: zhCn,
})
app.use(store)
app.use(router)
app.use(VueMarkdownEditorComponent)
for (const name in ElIcons) {
    app.component(name, ElIcons[name])
}
app.mount('#app')
