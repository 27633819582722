//菜单相关state
import {getAllMenu, getNavigateMenu} from '@/api/menu'
import router from '../router/index'
import {ElMessage} from 'element-plus'
import RouteUtils from "@/util/RouteUtils";
import StorageUtil from '../util/StorageUtil'

const initState = {
    isAuth: false, //是否登录
    menuTree: [],
    menuList: [],
    currentPage: {}, //当前页
    tagView: []
}

const state = StorageUtil.getLocal('state') || initState
// const state = StorageUtil.getSession('state') || initState

export default {
    namespace: true,
    state: state,
    getters: {
        HASMENU: (state) => (routePath) =>{
            return state.menuList.some( e=> {
                return e.path === routePath
            })
        }
    },
    mutations: {
        initState(state){
            // console.log('初始化', state)
            state.isAuth = initState.isAuth
            state.menuTree = initState.menuTree
            state.menuList = initState.menuList
            state.currentPage = initState.currentPage
            state.tagView = initState.tagView
            StorageUtil.clearLocal()
        },
        setAuth(state, value) {
            state.isAuth = value
        },
        setMenuTree(state, value) {
            state.menuTree = value
        },
        setMenuList(state, value) {
            state.menuList = value
        },
        setCurrentPage(state, value) {
            state.currentPage = RouteUtils.convertMenu(value)
        },
        addTag(state, value) {
            let tags = state.tagView
            let hasTag = tags.some((item)=>item.path === value.path)
            if (!hasTag){
                state.tagView.push(value)
            }
        },
        delTag(state, path){
            state.tagView = state.tagView.filter((item)=>{
                return item.path !== path
            })
            let enterMenu;
            if (state.tagView.length < 1){
                enterMenu = state.menuList[0]
            }else {
                enterMenu = state.tagView[state.tagView.length -1]
            }
            // console.log('路由变更',state.currentPage,path)
            if (state.currentPage.path === path){
                this.commit('addTag',{name: enterMenu.name,path: enterMenu.path})
                this.commit('setCurrentPage',enterMenu)
                router.push(enterMenu.path)
                // console.log('当前标签列表',state.tagView,this)
            }

        },
        refreshRouter(state){
            let menuList = state.menuList
            if (menuList !== undefined && menuList.length > 0){
                for (let i = 0; i < menuList.length; i++) {
                    let menu = RouteUtils.componentConvert(menuList[i])
                    if (router.hasRoute(menu.name)){
                        // console.log('移除路由:',menu.name)
                        router.removeRoute(menu.name)
                    }
                    router.addRoute('Home', menu)
                }
            }
        }
    },
    actions: {
        //加载菜单
        loadMenu(context) {
            // console.log('加载菜单')
            if (context.state.menuList.length < 1){
                getNavigateMenu({'structure': 'tree'}).then(res => {
                    if (res.success) {
                        let menuList = RouteUtils.treeToList(res.data, []);
                        context.commit('setMenuList', menuList)
                        context.commit('setMenuTree', res.data)
                        if (menuList !== undefined && menuList.length > 0){
                            for (let i = 0; i < menuList.length; i++) {
                                let menu = RouteUtils.componentConvert(menuList[i])
                                if (!router.hasRoute(menu.name)){
                                    router.addRoute('Home', menu)
                                }
                            }
                        }
                    } else {
                        ElMessage.error(res.msg);
                    }
                })
            }
        },
        refreshMenu(context){
           getNavigateMenu({'structure': 'tree'}).then(res => {
                if (res.success) {
                    let menuList = RouteUtils.treeToList(res.data, []);
                    context.commit('setMenuList', menuList)
                    context.commit('setMenuTree', res.data)
                    // console.log("刷新菜单。。。")
                    if (menuList !== undefined && menuList.length > 0){
                        for (let i = 0; i < menuList.length; i++) {
                            let menu = RouteUtils.componentConvert(menuList[i])
                            if (router.hasRoute(menu.name)){
                                // console.log('移除路由:',menu.name)
                                router.removeRoute(menu.name)
                            }
                            // if (!router.hasRoute(menu.name)){
                            router.addRoute('Home', menu)
                            // }
                        }
                    }
                    // console.log('刷新完成。。。', router.getRoutes())
                } else {
                    ElMessage.error(res.msg);
                }
            })
        }
    }
}