import {get, post} from "@/api/http";

//获取菜单
const getAllMenu = (params) => {
    return new Promise((resolve, reject) => {
        get('/admin/v1/menu/getAllMenu', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
//获取菜单
const getNavigateMenu = (params) => {
    return new Promise((resolve, reject) => {
        get('/admin/v1/menu/getMenu', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
//获取菜单
const getAllMenuTree = (params) => {
    return new Promise((resolve, reject) => {
        get('/admin/v1/menu/getMenuTree', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
//菜单详情
const getMenuDetail = (params) => {
    return new Promise((resolve, reject) => {
        get('/admin/v1/menu/detail', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const getMenuList = (params) => {
    return new Promise((resolve, reject) => {
        get('/admin/v1/menu/getMenuList', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const addMenu = (params) => {
    return new Promise((resolve, reject) => {
        post('/admin/v1/menu/addMenu', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const updateStatus = (params) => {
    return new Promise((resolve, reject) => {
        post('/admin/v1/menu/updateStatus', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const updateSort = (params) => {
    return new Promise((resolve, reject) => {
        post('/admin/v1/menu/sort', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const deleteMenu = (params) => {
    return new Promise((resolve, reject) => {
        post('/admin/v1/menu/delete', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const editMenu = (params) => {
    return new Promise((resolve, reject) => {
        post('/admin/v1/menu/update', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export {
    getAllMenu,
    getNavigateMenu,
    getMenuList,
    addMenu,
    getMenuDetail,
    editMenu,
    deleteMenu,
    getAllMenuTree,
    updateStatus,
    updateSort
}
