import router from './index'
import store from '../store/index'
import {ElMessage} from 'element-plus'

router.beforeEach((to , from ,next) => {
    // console.log('before:', router.getRoutes())
    if (router.hasRoute(to.name)){
        if (to.path === '/login') {
            next();
        }else {
            // console.log('正常跳转',store.state.menu.isAuth,to.name,router.hasRoute(to.name))
            // console.log('当前所有路由',router.getRoutes())
            if (store.state.menu.isAuth){
                if (store.state.menu.menuList.length < 1){
                    // console.log('loadMenu...')
                    store.dispatch('loadMenu')
                }
                next()
            }else {
                next('/login');
            }
        }
    }else {
        // console.log('查找路由',to.path, store.getters.HASMENU(to.path))
        if (store.getters.HASMENU(to.path)){
            next({ ...to, replace: true })
        }else {
            next('/404')
        }

    }
})

router.onError((err,to,form) =>{
    // console.log('导航异常',err.message,to,form)
    store.commit('setCurrentPage',form)
    if (err.message.includes('Cannot find module')){
        ElMessage.error('路由已存在，但未找到路由对应模块！')
    }else {
        ElMessage.error(err)
    }
})