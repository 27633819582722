<template>
  <router-view />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from 'vuex'
import StorageUtil from './util/StorageUtil'

export default {
  name: 'App',
  setup(){
    const  store = useStore()
    function saveState() {
      StorageUtil.setLocal('state', store.state.menu)
      // StorageUtil.setSession('state', store.state.menu)
    }
    onMounted(()=>{
      if (store.state.menu.isAuth){
        // store.dispatch('refreshMenu')
        store.commit('refreshRouter')
      }
      window.addEventListener('unload',saveState)
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}
.page-title-mx{
  width: 100%;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
}
.add-first-btn-mx{
  margin-bottom: 10px;
}

.disable-text-btn-mx{
  color: #ff4747 !important;
}
.enable-text-btn-mx{
  color: #74e144 !important;
}
.bottom-pagination-mx{
  margin-top: 14px;
  float: right;
}

.article-dialog {
  min-width: 840px;
}
.mx-pagination-size{
  display: inline-block;
  position: relative;
  top: 30px;
  right:66px;
}
.first-line-btn{
  height: 42px;
  /*background-color: #8c939d;*/
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
