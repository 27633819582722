import {createRouter, createWebHistory} from "vue-router";

const defaultRoutes = [
    {
        name: "Home",
        path: "/",
        component: () => import('../components/Container'),
        children: [
            {
                name: "info",
                path: "/info",
                component: () => import('../components/layout/toolbar/UserInfo.vue')
            }
        ]
    }, {
        name: "Login",
        path: "/login",
        component: () => import('../pages/other/Login')
    }, {
        name: "404",
        path: "/404",
        component: () => import('../components/NotFound')
    }, /*{
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }*/
]

export default createRouter({
    history: createWebHistory(),
    routes: defaultRoutes
})


